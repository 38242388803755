@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
}

a{
  text-decoration: none;
}

.profile-header{display:flex;flex-direction:column;justify-content:center;color:white;max-width:320px;margin:0 auto}.profile-header .picture-container{padding:20px 20px 0px 20px;display:flex;justify-content:center}.profile-header .picture-container img{border-radius:50%;width:150px;height:150px}.profile-header .profile-info{padding:20px;display:flex;flex-direction:column;justify-content:space-evenly;font-family:"Open Sans", sans-serif;font-size:smaller;color:rgba(255,255,255,0.623);text-shadow:1px 1px 1px rgba(1,1,1,0.4)}.profile-header .profile-info a{color:inherit}.profile-header .profile-info .display-name{font-family:"Open Sans", sans-serif;font-weight:bold;font-size:large;color:white;text-align:center}

.bg-image{position:absolute;overflow:hidden;z-index:-1;height:200%;width:200%;top:-50%;left:-50%;transform:translateZ(0);background-color:#dbdbdb;display:block;position:fixed}.bg-image img{position:absolute;top:0;right:0;bottom:0;left:50%;min-width:55%;min-height:55%;margin:auto;transform:translate3d(-50%, 0, 0);max-width:none;-webkit-filter:blur(30px);filter:blur(20px);opacity:.5}.profile-container{background-color:rgba(0,0,0,0)}.card{max-width:640px;margin:0 auto;height:100vh}

.card-header{height:50px;background-color:#e9e9e9;border-top-left-radius:30px;border-top-right-radius:30px;justify-content:center;padding:15px 10px}.card-header .menu{display:flex;justify-content:space-evenly;margin-bottom:10px;border:1px solid #0000001a;border-radius:19px;background-color:white}.card-header .menu input{display:none}.card-header .menu input:checked+label{color:white;background-color:#4a4a4a;box-shadow:0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);transition:all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)}.card-header .menu label{background-color:#ffffff}.card-header .menu label:hover{cursor:pointer}.card-header .menu .option{width:100%;text-align:center;color:gray;background-color:white;padding:5px;border-radius:19px}.card-header .menu .option.selected{color:black;background-color:white;box-shadow:0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)}

.footer{height:25px;background-color:#e9e9e9;border-bottom-left-radius:30px;border-bottom-right-radius:30px;justify-content:center}

.link-card{padding:10px 26px;display:flex;flex-direction:row;text-align:center}.link-card .image-container img{width:45px}.link-card .details{display:flex;flex-direction:column;justify-content:center;font-family:Montserrat;width:100%}.separator-line{margin:10px;border-bottom:1px solid rgba(0,0,0,0.233)}.link-card-wrapper{margin:10px;background-color:white;border-radius:15px;box-shadow:0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)}.link-card-wrapper:hover{box-shadow:0 4px 6px -1px rgba(0,0,0,0.2),0 2px 4px -1px rgba(0,0,0,0.2)}.link-card-wrapper:hover .fa-instagram-square{color:#c13584}.link-card-wrapper:hover .fa-twitter-square{color:#377dff}.link-card-wrapper:hover .fa-facebook-square{color:#1261f5}

.links-list{padding-top:15px;background-color:#e9e9e9;display:flex;flex-direction:column}


.group{position:relative;margin:45px 0}.group .form-input{background:none;background-color:white;color:grey;font-size:18px;padding:10px 10px 10px 5px;display:block;width:100%;border:none;border-radius:0;border-bottom:1px solid grey;margin:25px 0}.group .form-input:focus{outline:none}.group .form-input:focus ~ .form-input-label{top:-14px;font-size:12px;color:#000}.group input[type="password"]{letter-spacing:0.3em}.group .form-input-label{color:grey;font-size:16px;font-weight:normal;position:absolute;pointer-events:none;left:5px;top:10px;transition:300ms ease all}.group .form-input-label.shrink{top:-14px;font-size:12px;color:#000}

.custom-button{min-width:165px;width:auto;height:50px;letter-spacing:0.5px;line-height:50px;padding:0 35px 0 35px;font-size:15px;background-color:black;color:white;text-transform:uppercase;font-family:"Open Sans Condensed";font-weight:bolder;border:none;cursor:pointer}.custom-button:hover{background-color:white;color:black;border:1px solid black}.custom-button.google-sign-in{background-color:#4285f4;color:white}.custom-button.google-sign-in:hover{background-color:#357ae8;border:none}

.sign-in{width:30vw;display:flex;flex-direction:column}.sign-in .title{margin:10px 0px}


a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
