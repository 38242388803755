.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  max-width: 320px;
  margin: 0 auto;

  .picture-container {
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: center;

    img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
    }
  }

  .profile-info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    font-family: "Open Sans", sans-serif;
    font-size: smaller;
    color: rgba(255, 255, 255, 0.623);
    text-shadow: 1px 1px 1px rgba(1, 1, 1, 0.4);

    a {
      color: inherit;
    }

    .display-name {
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      font-size: large;
      color: white;
      text-align: center;
    }
  }
}
