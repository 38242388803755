.link-card{
  padding: 10px 26px;
  display: flex;
  flex-direction: row;
  text-align: center;

  .image-container{
    img{
      width: 45px;
    }
  }
  .details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Montserrat;
    width: 100%;
  }

}

.separator-line{
  margin: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.233);
}

.link-card-wrapper{
  margin: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.link-card-wrapper:hover{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  .fa-instagram-square{
    color: rgb(193,53,132);
  }
  .fa-twitter-square{
    color: rgb(55, 125, 255);
  }
  .fa-facebook-square{
    color: rgb(18, 97, 245);
  }
}
