.card-header {
  height: 50px;
  background-color: #e9e9e9;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  justify-content: center;
  padding: 15px 10px;

  .menu {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    border: 1px solid #0000001a;
    border-radius: 19px;
    background-color: white;

    input {
      display: none;
    }
    input:checked + label {
      color: white;
      background-color: #4a4a4a;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    label {
      background-color: #ffffff;
    }
    label:hover {
      cursor: pointer;
    }

    .option {
      width: 100%;
      text-align: center;
      color: gray;
      background-color: white;
      padding: 5px;
      border-radius: 19px;

      &.selected {
        color: black;
        background-color: white;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }
}
