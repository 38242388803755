.bg-image{
  position: absolute;
  overflow: hidden;
  z-index: -1;
  height: 200%;
  width: 200%;
  top: -50%;
  left: -50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  background-color: rgb(219, 219, 219);
  display: block;
  position: fixed;

  img{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    min-width: 55%;
    min-height: 55%;
    margin: auto;
    -webkit-transform: translate3d(-50%,0,0);
    transform: translate3d(-50%,0,0);
    max-width: none;
    -webkit-filter: blur(30px);
    filter: blur(20px);
    opacity: .5;
  }
}


.profile-container{
  background-color: rgba(0, 0, 0, 0);
}

.card{
  max-width: 640px;
  margin: 0 auto;
  height: 100vh;
}

